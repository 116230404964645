import { AllTeam } from "components/pages/ourTeam"
import Footer from "components/shared/Footer"
import HeaderTwo from "components/shared/HeaderTwo"
import React from "react"
import { Helmet } from "react-helmet"

const AllTeamMembers = () => {
	return (
		<>
			<Helmet>
				<html lang="en" />
				<title> MercurySols Technologies</title>
				{/* <meta name="description" content="" /> */}
			</Helmet>
			<HeaderTwo />
			<AllTeam />
			<Footer />
		</>
	)
}

export default AllTeamMembers
